import React from 'react';
import Layout from 'components/layout';
import Seo from 'components/seo';
import AboutUsComponent from 'components/@aboutus';

function AboutUsAppPage() {
  return (
    <Layout hideHeader={true} hideDownload={true}>
      <Seo title="Pintarnya Kerja | Tentang Kami" />
      <AboutUsComponent />
    </Layout>
  );
}

export default AboutUsAppPage;
